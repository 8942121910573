import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SingleProject from '../../components/SingleProject/SingleProject';

const ProjectPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pages {
            projects {
              metaTitle
              projects {
                name
                projectImages {
                  image
                  title
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    projects
  } = data.site.siteMetadata.pages.projects;

  return (<SingleProject name={projects[6].name} data={projects[6].projectImages} />)
};

export default ProjectPage;
